<!-- 第一行统计数据 -->
<template>
  <el-row :gutter="14">
    <el-col :lg="6" :sm="8" :xs="24">
      <el-card shadow="never">
        <sc-statistic title="总金额" image="money.svg" :value="sta.amount" suffix="￥">
        </sc-statistic>
      </el-card>
    </el-col>
    <el-col :lg="6" :sm="8" :xs="24">
      <el-card shadow="never">
        <sc-statistic title="总订单数量" image="order.svg" :value="sta.orderCount" suffix="单">
        </sc-statistic>
      </el-card>
    </el-col>
    <el-col :lg="6" :sm="8" :xs="24">
      <el-card shadow="never">
        <sc-statistic title="订单完成数量" image="order.svg" :value="sta.successCount" suffix="单">
        </sc-statistic>
      </el-card>
    </el-col>
    <el-col :lg="6" :sm="8" :xs="24">
      <el-card shadow="never">
        <sc-statistic title="订单成功率" image="number.svg" :value="sta.successRate" suffix="">
        </sc-statistic>
      </el-card>
    </el-col>
    <el-col :lg="8" :sm="8" :xs="24">
      <el-card shadow="never">
        <sc-statistic title="近一分钟订单量" image="order.svg" :value="sta.latestMinuteCount" suffix="单">
        </sc-statistic>
      </el-card>
    </el-col>
    <el-col :lg="8" :sm="8" :xs="24">
      <el-card shadow="never">
        <sc-statistic title="近一分钟订单完成量" image="order.svg" :value="sta.latestMinuteSuccessCount" suffix="单">
        </sc-statistic>
      </el-card>
    </el-col>
    <el-col :lg="8" :sm="8" :xs="24">
      <el-card shadow="never">
        <sc-statistic title="近一分钟成功率" image="number.svg" :value="sta.latestMinuteSuccessRate" suffix="">
        </sc-statistic>
      </el-card>
    </el-col>
    <el-col :lg="8" :sm="8" :xs="24">
      <el-card shadow="never">
        <sc-statistic title="上一分钟订单量" image="order.svg" :value="sta.lastMinuteCount" suffix="单">
        </sc-statistic>
      </el-card>
    </el-col>
    <el-col :lg="8" :sm="8" :xs="24">
      <el-card shadow="never">
        <sc-statistic title="上一分钟订单完成量" image="order.svg" :value="sta.lastMinuteSuccessCount" suffix="单">
        </sc-statistic>
      </el-card>
    </el-col>
    <el-col :lg="8" :sm="8" :xs="24">
      <el-card shadow="never">
        <sc-statistic title="上一分钟成功率" image="number.svg" :value="sta.lastMinuteSuccessRate" suffix="">
        </sc-statistic>
      </el-card>
    </el-col>
  </el-row>
</template>


<script>
import scStatistic from '@/components/scStatistic'

export default {
  components: {
    scStatistic,
  },
  data () {
    return {
      // 统计数据
      sta: {
        amount: 0,
        orderCount: 0,
        successCount: 0,
        successRate: 0,
        lastMinuteCount: 0,
        latestMinuteCount: 0,
        lastMinuteSuccessCount: 0,
        latestMinuteSuccessCount: 0,
        lastMinuteSuccessRate: '0',
        latestMinuteSuccessRate: 0,
      },

    }

  },
  props: ['role'],
  methods: {
    // 数值跳动
    // 对象、属性、结束值、所用时间
    slowMotion: function (obj, prop, endValue, time) {
      let timeNow = 0
      let fn = function () {
        // 如果已经接近 or 时间已到，则立即结束
        var jdz = Math.abs(obj[prop] - endValue)
        if (jdz < 2 || timeNow >= time) {
          // console.log('到点了');
          obj[prop] = endValue
        } else {
          if (jdz < 100) {
            obj[prop] += 1
          } else {
            obj[prop] += parseInt((endValue - obj[prop]) / 10) // 平均一下
          }
          timeNow += 30
          setTimeout(fn, 30)
        }
      }
      fn()
    },
    dataAnalysisToDay: function () {
      var defaultCfg2 = {
        type: 'get', //是否是请求体请求
        sleep: 50,
        msg: null,
      }
      var p1 = {}
      if (this.sa_admin.role != null && this.sa_admin.mchId != null) {
        if (this.sa_admin.role == 3) {
          p1 = {
            codeMchId: this.sa_admin.mchId,
          }
        } else if (this.sa_admin.role == 1) {
          p1 = {
            mchId: this.sa_admin.mchId,
          }
        }
        this.dataAnalysis(p1)
      } else {
        this.sa.ajax(
          '/getMerchantRole',
          function (res) {
            if (res.data.roles == 3) {
              p1 = {
                codeMchId: res.data.mchId,
              }
            } else if (res.data.roles == 1) {
              p1 = {
                mchId: res.data.mchId,
              }
            }
            this.dataAnalysis(p1)
          }.bind(this),
          defaultCfg2,
        )
      }
    },

    dataAnalysis: function (p1) {
      var defaultCfg1 = {
        isBody: true, //是否是请求体请求
      }
      this.sa.ajax(
        '/v1/dataAnalysis/dataAnalysisToDay',
        p1,
        function (res) {
          this.sta = res.data
          this.sta.amount = parseFloat(res.data.amount).toFixed(2)
        }.bind(this),
        defaultCfg1,
      )
    },

    // 设置统计数据的数值
    setStaDataValue: function (staData) {
      for (let key in staData) {
        this.slowMotion(this.sta, key, staData[key], 0)
      }
    },
  },
  created () {
    // 写入数据
    this.setStaDataValue({
      amount: 0,
      orderCount: 0,
      successCount: 0,
      successRate: '0%',
      lastMinuteCount: 0,
      latestMinuteCount: 0,
      lastMinuteSuccessCount: 0,
      latestMinuteSuccessCount: 0,
      lastMinuteSuccessRate: '0',
      latestMinuteSuccessRate: '0',
    })
    this.dataAnalysisToDay()
  },
}
</script>
<style scoped>
/* 第一行 */

.el-card {
  margin-top: 10px;
  border-radius: 20px;
}

.el-card:hover {
  background-color: #465EDA;
}

</style>
